<template>
     <div class="designer_application_orderDetail">
          <div class="title">申请单详情</div>
          <div class="des_app_detail">
               <div class="outer44 flex-col">
                    <div class="mod3 flex-col">
                         <div class="layer3 flex_row_between_center">
                              <div>
                                   <span class="txt10">申请单号：{{ detail.applySn }}</span>
                                   <span class="info16">{{ detail.stateValue }}</span>
                              </div>
                              <span class="txt10">
                                   申请时间：{{ detail.createTime }}
                              </span>
                         </div>
                         <div class="layer4 flex-col"></div>
                         <span class="word24">设计师信息</span>
                         <div class="layer5 flex-row">
                              <span class="txt11">设计师名称：{{ detail.designerName ? detail.designerName : '--' }}</span>
                              <span class="txt11">用户名：{{ detail.memberName }}</span>
                              <span class="txt11">手机号码：{{ detail.memberMobile ? detail.memberMobile : '--' }}</span>
                         </div>
                         <span class="word24">项目信息</span>
                         <div class="layer5 flex-row">
                              <span class="txt11">{{ detail.projectName }}</span>
                         </div>
                         <template v-if="orderList.length">
                              <span class="word24">订单信息</span>
                              <div class="layer5 flex-row" v-for="(item, index) in orderList" :key="index">
                                   <span class="txt11">
                                        <span>订单号：</span>
                                        <router-link :to="`/member/order/info?orderSn=${item.orderSn}`" target="_blank">
                                             <span class="txt12">{{ item.orderSn }}>></span>
                                        </router-link>
                                   </span>
                                   <span class="txt11" v-if="item.purchaseSn">采购单号：{{ item.purchaseSn }}</span>
                              </div>
                         </template>
                         <template v-if="detail.state == 4">
                              <span class="word24">拒绝理由</span>
                              <div class="layer5 flex-row">
                                   <span class="txt11">{{ detail.refuseReason }}</span>
                              </div>
                         </template>
                    </div>
               </div>
               <div class="pro_list">
                    <div class="pro_list_ava" v-if="goodsList.length">
                         <el-table ref="multipleTable" :data="goodsList" style="width: 100%"
                              @selection-change="handleSelectionChange">
                              <el-table-column type="selection" width="45" v-if="detail.state == 1"
                                   :selectable="selectable" />
                              <el-table-column label="图片" width="140" align="center">
                                   <template #default="scope">
                                        <div class="pro_img">
                                             <img :src="scope.row.goodsImage" alt="">
                                        </div>
                                   </template>
                              </el-table-column>
                              <el-table-column label="产品信息" width="190">
                                   <template #default="scope">
                                        <div>
                                             <div>{{ scope.row.goodsName }}</div>
                                             <div class="specValue">{{ scope.row.specValues }}</div>
                                             <br />
                                             <div v-if="scope.row.orderCode" class="orderCode">
                                                  订货编码：{{ scope.row.orderCode }}</div>

                                        </div>
                                   </template>
                              </el-table-column>
                              <el-table-column label="描述" width="190">
                                   <template #default="scope">
                                        <div class="bd14 flex-col">
                                             <div v-if="scope.row && scope.row.attributeValueList.length"
                                                  class="flex-col">
                                                  <span class="word95"
                                                       v-for="(attr, idx) in scope.row.attributeValueList"
                                                       :key="idx">{{ attr.attributeName }}:{{ attr.attributeValue }}</span>
                                             </div>
                                             <div v-else>
                                                  <span class="word95">--</span>
                                             </div>
                                        </div>
                                   </template>
                              </el-table-column>
                              <el-table-column property="phone" label="生产状态" width="120">
                                   <template #default="scope">
                                        <div class="bd15 flex-col">
                                             <span class="info54">{{ scope.row.produceStateValue }}</span>
                                             <span class="info54">发货日：{{ scope.row.deliverTime }}天</span>
                                        </div>
                                   </template>
                              </el-table-column>
                              <el-table-column label="价格" width="110">
                                   <template #default="scope">
                                        <div>
                                             <div class="bd16 flex-col" v-if="scope.row.goodsPrice">
                                                  ￥{{ scope.row.goodsPrice.toFixed(2) }}</div>
                                             <div v-else>--</div>
                                        </div>
                                   </template>
                              </el-table-column>
                              <el-table-column label="数量" fixed="right" width="160">
                                   <template #default="scope">
                                        <div class="section25" v-if="detail.state == 1">
                                             <div class="flex_row_start_center">
                                                  <div class="goods_edit_nem flex_row_center_center">
                                                       <span @click="editNum('minus', scope.row)">-</span>
                                                       <input type="number" @input="editNum('edit', scope.row)"
                                                            v-model="scope.row.goodsNum" :key="scope.row.goodsId"
                                                            @focus="editNum('focus', scope.row)"
                                                            @blur="editNum('blur', scope.row)" />
                                                       <span @click="editNum('plus', scope.row)">+</span>
                                                  </div>
                                                  <span class="txt50">{{ scope.row.saleUnit }}</span>
                                             </div>
                                             <span class="txt50">起订量{{ scope.row.minBuyNum }}</span>
                                             <div class="txt50">{{ scope.row.saleUnitDes }}</div>
                                        </div>
                                        <div class="section25" v-else>
                                             <span>{{ scope.row.goodsNum }}</span>
                                        </div>
                                   </template>
                              </el-table-column>
                         </el-table>
                    </div>
                    <div class="pro_list_dis" v-if="invalidList.length">
                         <div class="label">失效产品</div>
                         <div class="dis_table">
                              <el-table ref="multipleTable" :data="invalidList" style="width: 100%"
                                   :show-header="false">
                                   <el-table-column label="图片" width="140">
                                        <template #default="scope">
                                             <div class="pro_img disable">
                                                  <img :src="scope.row.goodsImage" alt="">
                                             </div>
                                        </template>
                                   </el-table-column>
                                   <el-table-column label="规格型号" width="190">
                                        <template #default="scope">
                                             <div class="specNo disable">{{ scope.row.specValues }}</div>
                                        </template>
                                   </el-table-column>
                                   <el-table-column label="描述" width="190">
                                        <template #default="scope">
                                             <div class="bd14 flex-col disable">
                                                  <div v-if="scope.row && scope.row.attributeValueList.length"
                                                       class="flex-col">
                                                       <span class="word95"
                                                            v-for="(attr, idx) in scope.row.attributeValueList"
                                                            :key="idx">{{ attr.attributeName }}:{{ attr.attributeValue }}</span>
                                                  </div>
                                                  <div v-else>
                                                       <span class="word95">--</span>
                                                  </div>
                                             </div>
                                        </template>
                                   </el-table-column>
                                   <el-table-column property="phone" label="生产状态" width="120">
                                        <template #default="scope">
                                             <div class="bd15 flex-col disable">
                                                  <span class="info54">{{ scope.row.produceStateValue }}</span>
                                                  <span class="info54">发货日：{{ scope.row.deliverTime }}天</span>
                                             </div>
                                        </template>
                                   </el-table-column>
                                   <el-table-column label="价格" width="110">
                                        <template #default="scope">
                                             <div class="bd16 flex-col disable">￥{{ scope.row.goodsPrice.toFixed(2) }}
                                             </div>
                                        </template>
                                   </el-table-column>
                                   <el-table-column label="操作" fixed="right" width="160">
                                        <template #default="scope">
                                             <div class="section25 disable" v-if="detail.state == 1">
                                                  <div class="flex_row_start_center">
                                                       <div class="goods_edit_nem flex_row_center_center">
                                                            <span @click="editNum('minus', scope.row)">-</span>
                                                            <input type="number" @input="editNum('edit', scope.row)"
                                                                 v-model="scope.row.goodsNum" :key="scope.row.goodsId"
                                                                 @focus="editNum('focus', scope.row)" />
                                                            <span @click="editNum('plus', scope.row)">+</span>
                                                       </div>
                                                       <span class="txt50">{{ scope.row.saleUnit }}</span>
                                                  </div>
                                                  <div class="txt50">{{ scope.row.saleUnitDes }}</div>
                                             </div>
                                             <div class="section25 disable" v-else>
                                                  <span>{{ scope.row.goodsNum }}</span>
                                             </div>
                                        </template>
                                   </el-table-column>
                              </el-table>
                         </div>
                    </div>
               </div>
          </div>
          <div class="order_bottom flex_row_end_center" v-if="detail.state == 1">
               <div class="op_right flex_row_start_center">
                    <template v-if="checkList.length">
                         <div class="txt flex_row_start_center">
                              <span class="txt1">已选择&nbsp;</span>
                              <span class="txt2">{{ proSum }}</span>
                              <span class="txt1">&nbsp;件产品</span>
                         </div>
                         <div class="txt flex_row_start_center" v-if="noInquire">
                              <span class="txt1">合计：</span>
                              <span class="txt2">￥{{ proPriceSum.toFixed(2) }}</span>
                         </div>
                    </template>

                    <div class="but1" @click="visiRef = true">
                         拒绝采购
                    </div>
                    <div class="but2" @click="toOrder">
                         去结算
                    </div>
               </div>
          </div>
          <el-dialog v-model="visiRef" width="600px" lock-sroll="false">
               <div class="group1 flex-col">
                    <div class="main11 flex-col">
                         <div class="mod1 flex-row">
                              <span class="txt1">拒绝采购</span>
                              <div class="label1 el-icon-close" @click="visiRef = false"></div>
                         </div>
                    </div>
                    <div class="main244 flex_row_center_center">
                         <table id="popTable">
                              <tr class="row3" valign="top">
                                   <td class="td_title"><i>*</i><span>拒绝理由：</span></td>
                                   <td>
                                        <el-input type="textarea" placeholder="请输入拒绝理由" rows="6" maxlength="100"
                                             v-model="refReason" show-word-limit>
                                        </el-input>
                                   </td>
                              </tr>
                         </table>
                    </div>
                    <div class="outer3 flex-col"></div>
                    <div class="outer40 flex-row">
                         <button class="outer5 flex-col" @click="visiRef = false">
                              <span class="word4">取消</span>
                         </button>
                         <button class="outer6 flex-col" @click="toRefuse">
                              <span class="word5">确定</span>
                         </button>
                    </div>
               </div>
          </el-dialog>
     </div>
</template>
<script>
import { getCurrentInstance, onMounted, reactive, ref, toRefs } from "vue";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { useRoute, useRouter } from "vue-router";
export default {
     setup() {
          const route = useRoute();
          const router = useRouter();
          const appId = ref(null)
          const { proxy } = getCurrentInstance();
          const state = reactive({
               detail: {},
               goodsList: [],
               orderList: [],
               invalidList: [],
               refReason: ''
          })
          const proSum = ref(0)
          const proPriceSum = ref(0)
          const preEditNum = ref(0)
          const checkList = ref([])
          const visiRef = ref(false)
          const visiOrder = ref(false)
          const noInquire = ref(true)
          const initDetail = () => {
               let param = {
                    applyId: appId.value
               }
               proxy.$get('v3/member/front/member/purchase/apply/detail', param).then(res => {
                    if (res.state == 200) {
                         state.detail = res.data
                         state.goodsList = res.data.goodsList.filter(item => item.state == 3)
                         state.invalidList = res.data.goodsList.filter(item => item.state != 3)
                         state.orderList = res.data.orderList
                    }
               })
          }

          const editNum = (type, scopeItem) => {

               let maxMin = Math.min(99999, scopeItem.productStock)
               switch (type) {
                    case 'minus': {
                         if (scopeItem.goodsNum <= scopeItem.minBuyNum) {
                              return
                         } else {
                              scopeItem.goodsNum--
                              if (proSum.value) {
                                   proPriceSum.value -= scopeItem.goodsPrice
                              }
                         }
                         break
                    }
                    case 'edit': {
                         if (!/^[1-9]\d*$/.test(scopeItem.goodsNum) && scopeItem.goodsNum) {
                              scopeItem.goodsNum = scopeItem.minBuyNum
                         } else if (proSum.value) {
                              if (scopeItem.goodsNum > maxMin) {
                                   scopeItem.goodsNum = maxMin
                              }
                              proPriceSum.value += ((scopeItem.goodsNum - preEditNum.value) * scopeItem.goodsPrice)
                         }

                         if (scopeItem.goodsNum > maxMin) {
                              scopeItem.goodsNum = maxMin
                         }
                         preEditNum.value = scopeItem.goodsNum
                         break
                    }
                    case 'plus': {
                         scopeItem.goodsNum++
                         if (scopeItem.goodsNum > maxMin) {
                              scopeItem.goodsNum = maxMin
                              ElMessage('已超过限定数量')
                         } else {
                              if (proSum.value) {
                                   proPriceSum.value += scopeItem.goodsPrice
                              }
                         }


                         break;
                    }
                    case 'focus': {
                         preEditNum.value = scopeItem.goodsNum
                         break;
                    }

                    case 'blur': {
                         if (scopeItem.goodsNum <= scopeItem.minBuyNum || !scopeItem.goodsNum) {
                              scopeItem.goodsNum = scopeItem.minBuyNum
                         }
                         break;
                    }
               }

               if (proSum.value) {
                    let idx = checkList.value.findIndex(i => {
                         let tart = i.defaultProductId || i.productId
                         let ori = scopeItem.defaultProductId || scopeItem.productId
                         return tart == ori
                    })
                    checkList.value[idx].num = scopeItem.goodsNum
               }

          }

          const handleSelectionChange = (selec) => {
               proSum.value = selec.length
               checkList.value = selec.map(item => {
                    return {
                         productId: item.defaultProductId ? item.defaultProductId : item.productId,
                         num: item.goodsNum
                    }
               })
               noInquire.value = selec.every(i => i.goodsPrice > 0)
               proPriceSum.value = selec.reduce((total, obj) => total + (obj.goodsPrice * obj.goodsNum), 0)
          }

          const toOrder = () => {

               if (!checkList.value.length) {
                    ElMessage('请选择产品')
                    return
               }

               let tmp = checkList.value.map(i => `${i.productId}-${i.num}`)
               let query = {
                    productSets: encodeURIComponent(tmp.join(',')),
                    applySn: state.detail.applySn
               }
               router.push({
                    path: "/buy/confirm",
                    query
               })
          }

          const toRefuse = () => {
               if (!state.refReason.trim()) {
                    ElMessage('请输入拒绝理由')
                    return
               }

               let param = {
                    applyId: state.detail.applyId,
                    refuseReason: state.refReason.trim()
               }

               proxy.$post('v3/member/front/member/purchase/apply/reject', param).then(res => {
                    if (res.state == 200) {
                         ElMessage.success(res.msg)
                         setTimeout(() => {
                              history.go(0)
                         }, 500)
                         visiRef.value = false
                    } else {
                         ElMessage.error(res.msg)
                    }
               })
          }


          const selectable = (row, index) => {
               if (row.goodsNum > row.productStock) {
                    return false
               } else {
                    return true
               }

          }

          onMounted(() => {
               appId.value = route.query.appId
               initDetail()
          })

          return {
               ...toRefs(state),
               toOrder,
               handleSelectionChange,
               editNum,
               proSum,
               handleSelectionChange,
               proPriceSum,
               visiRef,
               toRefuse,
               visiOrder,
               checkList,
               noInquire,
               selectable
          }
     }
}
</script>
<style lang="scss" scoped>
.pro_list_ava {

     .el-checkbox__input.is-checked .el-checkbox__inner,
     .el-checkbox__input.is-indeterminate .el-checkbox__inner {
          background-color: $colorMain;
          border-color: $colorMain;
     }
}

.designer_application_orderDetail {
     float: left;
     width: 1009px;
     margin-left: 15px;

     .title {
          height: 60px;
          border-bottom: 1px solid #EEEEEE;
          line-height: 60px;
          padding-left: 20px;
          font-size: 16px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #666666;
          background-color: #fff;
     }

     .des_app_detail {
          padding: 20px;
          background-color: #fff;

          .outer44 {
               padding: 16px;
               min-height: 190px;
               background-color: rgba(247, 247, 247, 1);

               justify-content: center;
               align-items: center;

               .mod3 {
                    width: 100%;

                    .txt10 {
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                    }

                    .info16 {
                         color: rgba(40, 95, 222, 1);
                         font-size: 14px;
                         margin-left: 18px;
                    }

                    .layer4 {
                         height: 2px;
                         background: #eee;
                         margin-top: 18px;
                    }

                    .word24 {
                         color: #222;
                         margin-top: 20px;
                    }

                    .layer5 {
                         margin-top: 15px;
                         word-break: break-all;

                         .txt11 {
                              color: rgba(102, 102, 102, 1);
                              font-size: 12px;
                              margin-right: 12px;
                              word-break: break-all;
                         }

                         .txt12 {
                              color: $colorMain;
                              font-size: 12px;
                              margin-right: 12px;
                              line-height: 15px;
                              border-bottom: 1px solid $colorMain;
                         }
                    }
               }

          }

          .pro_list {

               margin-top: 20px;

               .pro_img {
                    width: 106px;
                    height: 106px;
                    background: #FFFFFF;
                    border: 1px solid #E2E2E2;
                    margin: 0 auto;

                    img {
                         width: 103px;
                         height: 103px;
                    }
               }

               .bd14 {
                    .word95 {
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         line-height: 20px;
                    }
               }

               .bd15 {
                    .info54 {
                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         color: #686868;
                         line-height: 20px;
                    }
               }

               .bd16 {
                    font-size: 12px;
                    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                    font-weight: bold;
                    color: #F30213;
                    line-height: 18px;
               }

               .pro_list_ava {
                    border: 1px solid #F4F4F4;
               }

               .section25 {

                    justify-content: space-between;

                    .txt50 {

                         font-size: 12px;
                         font-family: SourceHanSansCN-Regular;

                         margin-top: 7px;
                    }

                    .goods_edit_nem {

                         background: #ffffff;
                         border: 1px solid #dddddd;
                         margin-right: 10px;

                         span {
                              width: 26px;
                              height: 30px;
                              background: #fff;
                              text-align: center;
                              display: block;
                              line-height: 30px;
                              cursor: pointer;
                         }

                         input {
                              width: 44px;
                              height: 30px;
                              border: none;
                              border-left: 1px solid #dddddd;
                              border-right: 1px solid #dddddd;
                              text-align: center;
                              line-height: 30px;
                         }

                         /*** 消除input元素 type="number" 时默认的 加减按钮*/
                         input[type="number"]::-webkit-inner-spin-button,
                         input[type="number"]::-webkit-outer-spin-button {
                              -webkit-appearance: none;
                              margin: 0;
                         }

                         /*** 消除input元素 type="number" 时默认的 加减按钮---moz版*/
                         input[type="number"] {
                              -moz-appearance: textfield;
                         }
                    }
               }
          }

          .pro_list_dis {
               margin-top: 30px;


               .label {
                    font-size: 14px;
                    font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                    font-weight: bold;
                    color: #666666;
                    margin-bottom: 20px;
               }

               .dis_table {
                    border: 1px solid #F4F4F4;
               }
          }




     }

     .order_bottom {
          margin-top: 40px;
          height: 80px;
          background: #F3F3F3;
          border: 1px solid #F0F0F0;
          padding-right: 20px;

          .op_left {
               padding-left: 14px;

               .txtMain {
                    font-size: 14px;
                    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                    font-weight: 400;
                    color: $colorMain;
                    margin-left: 20px;
               }
          }

          .op_right {
               .txt {
                    margin-right: 20px;

                    .txt1 {
                         font-size: 14px;
                         font-family: SourceHanSansCN-Regular, SourceHanSansCN;
                         font-weight: 400;
                         color: #333333;
                    }

                    .txt2 {
                         font-size: 18px;
                         font-family: SourceHanSansCN-Bold, SourceHanSansCN;
                         font-weight: bold;
                         color: $colorMain2;
                    }
               }

               .but1 {
                    width: 112px;
                    height: 44px;
                    border: 1px solid $colorMain;
                    font-size: 20px;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-weight: 600;
                    color: $colorMain;
                    text-align: center;
                    line-height: 44px;
                    margin-right: 20px;
                    cursor: pointer;
               }

               .but2 {
                    width: 112px;
                    height: 44px;
                    background: $colorMain;
                    font-size: 20px;
                    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
                    font-weight: 500;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 44px;
                    cursor: pointer;
               }
          }
     }

     .disable {
          opacity: 0.3;
          color: #666 !important;
     }

     .main1,
     .main11 {
          height: 46px;
          border-radius: 4px 4px 0 0;
          background-color: rgba(248, 248, 248, 1);
          width: 100%;
          justify-content: center;
          align-items: flex-start;
          padding-left: 20px;

          .mod1 {
               z-index: auto;
               height: 16px;
               justify-content: space-between;
               width: 100%;
               padding-right: 15px;

               .txt1 {
                    display: block;
                    color: rgba(51, 51, 51, 1);
                    font-size: 14px;
                    text-align: left;
                    margin-top: 1px;
               }

               .label1 {
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
               }
          }
     }

     .main244 {
          padding-right: 40px;
          padding-top: 20px;
     }

     .main22 {
          z-index: 11;
          height: 309px;
          border-radius: 3px 3px 0 0;
          align-self: center;
          margin-top: 20px;
          width: 747px;
          justify-content: flex-start;

          .mod2 {
               z-index: auto;
               width: 747px;
               height: 297px;

               .group2 {
                    height: 40px;
                    border-radius: 3px 3px 0 0;
                    background: #eee;
                    padding-left: 10px;

                    .word0 {
                         width: 56px;
                         height: 40px;
                    }

                    .word1 {

                         width: 126px;
                         height: 40px;
                         text-align: center;
                         font-size: 14px;
                         line-height: 40px;

                    }

                    .word2 {

                         width: 156px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;
                    }

                    .word3 {

                         width: 120px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;

                    }

                    .word4 {
                         width: 120px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;
                    }

                    .word5 {
                         width: 140px;
                         height: 40px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 40px;
                    }


               }

               .group3 {
                    height: 53px;
                    padding-left: 10px;

                    .word6 {
                         width: 56px;
                         height: 53px;
                         display: block;

                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 53px;
                    }

                    .txt2 {

                         width: 126px;
                         height: 53px;
                         display: block;

                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 53px;
                    }

                    .info1 {

                         width: 156px;
                         height: 53px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 53px;
                    }

                    .word7 {

                         width: 120px;
                         height: 53px;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         line-height: 53px;
                         text-align: center;

                    }

                    .txt3 {

                         width: 120px;
                         height: 53px;
                         display: block;
                         color: rgba(102, 102, 102, 1);
                         font-size: 14px;
                         text-align: center;
                         line-height: 53px;
                    }

                    .bd2 {
                         height: 53px;
                         border-radius: 2px;
                         border: none;
                         background-color: rgba(255, 255, 255, 1);
                         width: 140px;
                         justify-content: center;
                         align-items: center;

                         .word8 {
                              width: 95px;
                              height: 30px;
                              display: block;
                              color: rgba(40, 95, 222, 1);
                              border: 1px solid rgba(40, 95, 222, 1);
                              font-size: 14px;
                              text-align: center;
                              line-height: 30px;
                         }
                    }

               }
          }
     }

     .outer3 {
          z-index: 5;
          width: 600px;
          height: 1px;
          background: #f7f7f7;
          margin-top: 72px;
     }

     .outer40 {
          z-index: auto;
          width: 100%;
          height: 34px;
          justify-content: center;
          margin: 15px 0;

          .outer5 {

               height: 34px;
               border-radius: 2px;
               border: 1px solid rgba(217, 217, 217, 1);
               background-color: rgba(255, 255, 255, 1);
               width: 90px;
               justify-content: center;
               align-items: center;
               cursor: pointer;
               margin-right: 20px;

               .word4 {

                    color: rgba(0, 0, 0, 0.65);
                    font-size: 14px;

                    line-height: 20px;
                    text-align: center;
               }
          }

          .outer6 {
               height: 34px;
               border-radius: 2px;
               background-color: $colorMain;
               width: 90px;
               justify-content: center;
               align-items: center;
               border: none;
               cursor: pointer;

               .word5 {
                    color: rgba(255, 255, 255, 1);
                    font-size: 14px;
                    letter-spacing: -0.33764705061912537px;
                    line-height: 20px;
                    text-align: center;
               }
          }
     }
}

#popTable {

     border-spacing: 10px;

     .td_title {
          text-align: right;
          font-size: 14px;
          font-family: SourceHanSansCN-Regular, SourceHanSansCN;
          font-weight: 400;
          color: #666666;
     }

     .tip {
          color: #C0C4CC;
     }

     .el-input {
          width: 338px;
     }

     .el-input__inner {
          width: 338px;
          font-size: 13px;
          padding-right: 0;
          border-radius: 2px;
          padding-bottom: 1px;

     }

     i {
          color: #e2231a;
          font-style: normal;
     }

     .message {
          font-size: 12px;
          line-height: 2;
          padding-top: 4px;
          color: #e2231a;
          font-family: Microsoft YaHei;
     }

     td {
          max-width: 338px;

          &.title {
               padding-top: 9px;
          }
     }

     textarea {
          resize: none;
          width: 310px;
     }
}
</style>
<style lang="scss">
.has-gutter {
     background: #F4F4F4 !important;
}

.el-table thead tr {
     background-color: #F4F4F4;
}

.el-table th {
     background-color: #F4F4F4;
}

.designer_application_orderDetail {
     .el-dialog__header {
          display: none;
     }

     .el-dialog__body {
          padding: 0;
     }
}

.specValue {
     font-size: 12px;
     color: #999;
}

.orderCode {
     font-size: 12px;

}
</style>